// This file is generated by compile-translations.js script
// prettier-ignore
module.exports = [
  {
    "languageCode": "af_ZA",
    "languageName": "Afrikaans",
    "languageNativeName": "Afrikaans",
    "translationRatio": 0.04998337581735568
  },
  {
    "languageCode": "ar_SA",
    "languageName": "Arabic",
    "languageNativeName": "اَلْعَرَبِيَّةُ",
    "translationRatio": 0.9906904577191621
  },
  {
    "languageCode": "az_AZ",
    "languageName": "Azerbaijani",
    "languageNativeName": "azərbaycan dili",
    "translationRatio": 0.04089548930510911
  },
  {
    "languageCode": "bg_BG",
    "languageName": "Bulgarian",
    "languageNativeName": "български език",
    "translationRatio": 0.09852598913886734
  },
  {
    "languageCode": "bn_BD",
    "languageName": "Bengali",
    "languageNativeName": "বাংলা",
    "translationRatio": 0.055303114263548725
  },
  {
    "languageCode": "br_FR",
    "languageName": "Breton",
    "languageNativeName": "brezhoneg",
    "translationRatio": 0.028704422032583388
  },
  {
    "languageCode": "ca_ES",
    "languageName": "Catalan",
    "languageNativeName": "Català",
    "translationRatio": 0.9540064280172892
  },
  {
    "languageCode": "cs_CZ",
    "languageName": "Czech",
    "languageNativeName": "čeština",
    "translationRatio": 0.9859248586944476
  },
  {
    "languageCode": "da_DK",
    "languageName": "Danish",
    "languageNativeName": "Dansk",
    "translationRatio": 0.9767261442979054
  },
  {
    "languageCode": "de_DE",
    "languageName": "German",
    "languageNativeName": "Deutsch",
    "translationRatio": 0.9801618087110717
  },
  {
    "languageCode": "el_GR",
    "languageName": "Greek",
    "languageNativeName": "Ελληνικά",
    "translationRatio": 0.9919095644464148
  },
  {
    "languageCode": "en",
    "languageName": "English",
    "languageNativeName": "English",
    "translationRatio": 0.0733997155049787
  },
  {
    "languageCode": "eo_UY",
    "languageName": "Esperanto",
    "languageNativeName": "Esperanto",
    "translationRatio": 0.989693006760501
  },
  {
    "languageCode": "es_ES",
    "languageName": "Spanish",
    "languageNativeName": "Español",
    "translationRatio": 0.9861465144630389
  },
  {
    "languageCode": "fa_IR",
    "languageName": "Persian",
    "languageNativeName": "فارسی",
    "translationRatio": 0.9936828105951457
  },
  {
    "languageCode": "fi_FI",
    "languageName": "Finnish",
    "languageNativeName": "suomi",
    "translationRatio": 0.988584727917544
  },
  {
    "languageCode": "fil_PH",
    "languageName": "Filipino",
    "languageNativeName": "Mga Filipino",
    "translationRatio": 0.9628726587609443
  },
  {
    "languageCode": "fr_FR",
    "languageName": "French",
    "languageNativeName": "Français",
    "translationRatio": 0.9781669067937493
  },
  {
    "languageCode": "ha_HG",
    "languageName": "Hausa",
    "languageNativeName": "هَوُسَ",
    "translationRatio": 0.03369167682588936
  },
  {
    "languageCode": "he_IL",
    "languageName": "Hebrew",
    "languageNativeName": "עברית",
    "translationRatio": 0.17322398315416154
  },
  {
    "languageCode": "hi_IN",
    "languageName": "Hindi",
    "languageNativeName": "हिन्दी",
    "translationRatio": 0.994680261553807
  },
  {
    "languageCode": "hu_HU",
    "languageName": "Hungarian",
    "languageNativeName": "magyar",
    "translationRatio": 0.9873656211902915
  },
  {
    "languageCode": "id_ID",
    "languageName": "Indonesian",
    "languageNativeName": "Bahasa Indonesia",
    "translationRatio": 0.9821567106283942
  },
  {
    "languageCode": "ig_NG",
    "languageName": "Igbo",
    "languageNativeName": "Asụsụ Igbo",
    "translationRatio": 0.03236174221434118
  },
  {
    "languageCode": "it_IT",
    "languageName": "Italian",
    "languageNativeName": "Italiano",
    "translationRatio": 0.9808267760168459
  },
  {
    "languageCode": "ja_JP",
    "languageName": "Japanese",
    "languageNativeName": "日本語",
    "translationRatio": 0.9921312202150061
  },
  {
    "languageCode": "ka_GE",
    "languageName": "Georgian",
    "languageNativeName": "ქართული",
    "translationRatio": 0.11814252465920427
  },
  {
    "languageCode": "km_KH",
    "languageName": "Khmer",
    "languageNativeName": "ខេមរភាសា",
    "translationRatio": 0.0329158816358196
  },
  {
    "languageCode": "ko_KR",
    "languageName": "Korean",
    "languageNativeName": "한국어",
    "translationRatio": 0.9916879086778233
  },
  {
    "languageCode": "lt_LT",
    "languageName": "Lithuanian",
    "languageNativeName": "lietuvių kalba",
    "translationRatio": 0.9932394990579629
  },
  {
    "languageCode": "lv_LV",
    "languageName": "Latvian",
    "languageNativeName": "latviešu valoda",
    "translationRatio": 0.028704422032583388
  },
  {
    "languageCode": "mr_IN",
    "languageName": "Marathi",
    "languageNativeName": "मराठी",
    "translationRatio": 0.04023052199933508
  },
  {
    "languageCode": "ms_MY",
    "languageName": "Malay",
    "languageNativeName": "Bahasa Melayu",
    "translationRatio": 0.05685470464368836
  },
  {
    "languageCode": "my_MM",
    "languageName": "Burmese",
    "languageNativeName": "ဗမာစာ",
    "translationRatio": 0.031031807602792894
  },
  {
    "languageCode": "nl_NL",
    "languageName": "Dutch",
    "languageNativeName": "Nederlands",
    "translationRatio": 0.9743987587276959
  },
  {
    "languageCode": "no_NO",
    "languageName": "Norwegian",
    "languageNativeName": "Norsk",
    "translationRatio": 0.9854815471572648
  },
  {
    "languageCode": "pl_PL",
    "languageName": "Polish",
    "languageNativeName": "Polski",
    "translationRatio": 0.9870331375374044
  },
  {
    "languageCode": "pseudo_LOCALE",
    "languageName": "for development only",
    "languageNativeName": "Pseudolocalization",
    "translationRatio": 0.9960170697012802
  },
  {
    "languageCode": "pt_BR",
    "languageName": "Brazilian Portuguese",
    "languageNativeName": "Português brasileiro",
    "translationRatio": 0.9871439654217001
  },
  {
    "languageCode": "pt_PT",
    "languageName": "Portuguese",
    "languageNativeName": "Português",
    "translationRatio": 0.9868114817688131
  },
  {
    "languageCode": "ro_RO",
    "languageName": "Romanian",
    "languageNativeName": "Română",
    "translationRatio": 0.098304333370276
  },
  {
    "languageCode": "ru_RU",
    "languageName": "Russian",
    "languageNativeName": "Русский",
    "translationRatio": 0.9898038346447966
  },
  {
    "languageCode": "si_LK",
    "languageName": "Sinhala",
    "languageNativeName": "සිංහල",
    "translationRatio": 0.9956777125124682
  },
  {
    "languageCode": "sk_SK",
    "languageName": "Slovak",
    "languageNativeName": "slovenčina",
    "translationRatio": 0.9886955558018398
  },
  {
    "languageCode": "sl_SI",
    "languageName": "Slovenian",
    "languageNativeName": "slovenščina",
    "translationRatio": 0.9915770807935277
  },
  {
    "languageCode": "sq_AL",
    "languageName": "Albanian",
    "languageNativeName": "Shqip",
    "translationRatio": 0.9855923750415605
  },
  {
    "languageCode": "sr_CS",
    "languageName": "Serbian (Latin)",
    "languageNativeName": "srpski",
    "translationRatio": 0.9873656211902915
  },
  {
    "languageCode": "sr_SP",
    "languageName": "Serbian",
    "languageNativeName": "српски језик",
    "translationRatio": 0.9930178432893716
  },
  {
    "languageCode": "sv_SE",
    "languageName": "Swedish",
    "languageNativeName": "Svenska",
    "translationRatio": 0.9842624404300122
  },
  {
    "languageCode": "sw_KE",
    "languageName": "Swahili",
    "languageNativeName": "Kiswahili",
    "translationRatio": 0.028815249916879115
  },
  {
    "languageCode": "th_TH",
    "languageName": "Thai",
    "languageNativeName": "ไทย",
    "translationRatio": 0.9826000221655768
  },
  {
    "languageCode": "tr_TR",
    "languageName": "Turkish",
    "languageNativeName": "Türkçe",
    "translationRatio": 0.9844840961986036
  },
  {
    "languageCode": "uk_UA",
    "languageName": "Ukrainian",
    "languageNativeName": "Українська",
    "translationRatio": 0.9903579740662751
  },
  {
    "languageCode": "ur_PK",
    "languageName": "Urdu",
    "languageNativeName": "اردو",
    "translationRatio": 0.03790313642912557
  },
  {
    "languageCode": "uz_UZ",
    "languageName": "Uzbek",
    "languageNativeName": "Ўзбек",
    "translationRatio": 0.03635154604898594
  },
  {
    "languageCode": "vi_VN",
    "languageName": "Vietnamese",
    "languageNativeName": "Tiếng Việt",
    "translationRatio": 0.9892496952233182
  },
  {
    "languageCode": "yo_NG",
    "languageName": "Yoruba",
    "languageNativeName": "Yorùbá",
    "translationRatio": 0.9829325058184639
  },
  {
    "languageCode": "zh_CN",
    "languageName": "Chinese Simplified",
    "languageNativeName": "简化字",
    "translationRatio": 0.9923528759835974
  },
  {
    "languageCode": "zh_TW",
    "languageName": "Chinese Traditional",
    "languageNativeName": "正體字",
    "translationRatio": 0.9930178432893716
  }
];
